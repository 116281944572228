import { Fragment, createElement } from 'react';
import { createRoot } from 'react-dom/client';
import { createPortal } from 'react-dom';

import {
    DefaultTestReact
} from '../../react-test/js/providers';

import {
    VideoMediaKind
} from '../../video-media-kind/js/providers'


(function (app) {
    /**
     * renderMap will contain a mapping of React "Provider" components to DOM selectors.
     * The React "component" will be rendered into any DOM elements matching the "node" selector.
     */
    const renderMap = [
        {
            node: '.js-react-test',
            component: DefaultTestReact
        },
        {
            node: '.js-react-video-player-mk',
            component: VideoMediaKind
        }
    ];

    /**
     * Filter a list of DOM nodes to those that exist on the page,
     * Get a reference to the Nodes and map them to a React component,
     * Create a React Portal for each Node (so we can render React components into the same React tree, but not the same DOM tree).
     *
     * history can be used to pass in a reference to the value of the `history/createBrowserHistory` package function,
     * in case we need a package like "connected-react-router" to sync router state with the redux store.
     *
     * @param { Array } renderMapArr
     * @param history
     */
    const render = (renderMapArr, history) => {
        const children = renderMapArr
            .filter((item) => document.querySelector(item.node))
            .map((item) =>
                [...document.querySelectorAll(item.node)].map((node) => {
                    if ( !node.dataset.initiated ) {
                        node.dataset.initiated = true;
                        return ({
                            node,
                            component: item.component
                        }) 
                    }

                    return null;
                })
            )
            .flat()
            .map((item) => {
                if ( item ) {
                    return createPortal(
                        // we could alternatively spread "data" here if we don't want to nest any attributes
                        createElement(item.component, {
                            store: app.redux.store,
                            history,
                            data: item.node.dataset
                        }),
                        item.node
                    );
                }

                return null
            })
            .filter( (item) => item !== null)

        // if we have any matching DOM selectors, render the matching React components
        if (children.length) {
            const container = document.createElement('div');
            const root = createRoot(container);
            root.render(createElement(Fragment, null, children));
        }
    };

    const setListeners = () => {
        window.addEventListener(
            app.common.constants.EVENTS.SCRIPTS.LIVE_BLOG.LOADED, () => {
                render( renderMap )
            }
        );
    }

    // wait for common to load, then run the bootstrap process
    new Promise((resolve) => {
        window.isCommonLoaded === true
            ? resolve()
            : window.addEventListener(
                  app.common.constants.EVENTS.SCRIPTS.COMMON.LOADED,
                  resolve
              );
    }).then(() => {
        render(renderMap)
        setListeners();
    });
})(PULSE.app);
