import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchWithRetryBQ } from './base-queries/fetch-with-retry';

const { app } = PULSE;

// RTK Query update: Define an api service with a base URL and retry attempts
// RTK Query update: Endpoints can be added here or injected in components
export const contentApi = createApi({
    reducerPath: 'contentApi',
    baseQuery: fetchWithRetryBQ(app.environment.api),
    endpoints: (builder) => ({
        // GET /content/{accountName}/{type}/{lang}/{id} - Get Content by type/lang and ID
        getContentItem: builder.query({
            query: ({ type, id, params }) =>
                app.common.createContentPath(type, params, 'en', id)
        }),
        // GET /content/{accountName}/{type}/{lang} - Get Content by type/lang
        getContent: builder.query({
            query: ({ type, params }) => {
                return `/content/${app.account}/${type}/${
                    'en'
                }${app.common.prepareParams(params)}`;
            }
        }),
        // GET /content/{accountName}/all/{lang}
        getAllContent: builder.query({
            query: ({ params }) => {
                return `/content/${app.account}/all/${
                    'en'
                }${app.common.prepareParams(params)}`;
            }
        })
    })
});

export const {
    useGetContentItemQuery,
    useGetContentQuery,
    useGetAllContentQuery
} = contentApi;
